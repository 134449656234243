.sorting-visualized {
  display: block;
  background-color: white;
  padding: 20px 30px 20px 30px;
}

.sorting-visualized .header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px 0;
}

.sorting-visualized .title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
}

.sorting-visualized .logo {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: baseline;
  margin: 0 0 16px 0;
}

.sorting-visualized .styles {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.sorting-visualized .styles .selector {
  background: none;
	border: none;
	padding: 0;
  cursor: pointer;
  outline: none;
  margin: 0 4px 0 0;
  text-decoration: none;
  transition: all .25s;
  color: gray;
}

.sorting-visualized .settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.sorting-visualized .slider {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}

.sorting-visualized .slider-input {
  background: #cccccc;
  width: 150px;
  height: 2px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.sorting-visualized .slider-input:hover {
  opacity: 1;
}

.sorting-visualized .slider-input::-webkit-slider-thumb {
  cursor: pointer;
}

.sorting-visualized .slider-input::-moz-range-thumb {
  cursor: pointer;
}

.sorting-visualized .slider-input:disabled {
  opacity: 0.3;
}

.sorting-visualized .text {
  margin: 0;
}

.sorting-visualized .status {
  font-weight: 600;
  margin: 0;
}

.sorting-visualized .container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
}

.sorting-visualized .list {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 25px;
  transition: align-items .5s;
}

.sorting-visualized .buttons {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.sorting-visualized .buttons .button {
  background: #f7f7f7;
  width: 104px;
  height: 32px;
  margin: 0 8px 8px 8px;
  border: solid 2px lightgray;
  border-radius: 4px;
  transition: box-shadow 0.2s;
  cursor: pointer;
}

.sorting-visualized .buttons .button:hover {
  background: #fafafa;
  box-shadow: 3px 3px 3px #eaeaea;
}

.sorting-visualized .buttons .button:active {
  box-shadow: inset 1px 1px 1px #dfdfdf;
  color: black;
}

.sorting-visualized .list .bar {
  display: flex;
  width: 4px;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
}

@media only screen and (max-width: 750px) {
  .sorting-visualized .text {
    text-align: center;
  }
  .sorting-visualized .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .sorting-visualized .title {
    align-items: center;
  }

  .sorting-visualized .styles {
    margin: 0 0 24px 0;
  }
  
  .sorting-visualized .list .bar {
    width: 1px;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 1px;
  }  
}
