:root {
  --main-bg-color: white;
  --main-secondary-bg-color: white;
  --main-color: black;
  --main-secondary-color: gray;
  --main-tertiary-color: #e0e0e0;
  --main-navbar-bg-color: white;
  --main-navbar-color: black;
}
@media screen and (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: #1d1d1d;
    --main-secondary-bg-color: #2d2d2d;
    --main-color: white;
    --main-secondary-color: lightgray;
    --main-tertiary-color: #1d1d1d;
    --main-navbar-bg-color: #111111;
    --main-navbar-color: white;
  }
  .homepage .navbar svg {
    fill: white;
  }
}

.homepage {
  background-color: var(--main-bg-color);
  color: var(--main-color);
  width: 100%;
  padding: 0px;
  margin: 0px;
  padding-top: 46px;
  font-family: "Sen", sans-serif;
}

.homepage .heading {
  padding: 0px;
  margin: 0px;
}
.homepage .p {
  font-size: 18px;
  line-height: 24px;
  margin: 0px;
  padding: 0px;
}
.homepage #profile {
  width: 240px;
  max-width: 100%;
  border-radius: 36px;
  float: left;
  padding: 24px;
  padding-right: 16px;
}

.homepage .nav {
  display: block;
  cursor: default;
  list-style: none;
}
.homepage .navbar {
  background-color: var(--main-navbar-bg-color);
  opacity: 0.98;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-left: 24px;
  padding-right: 24px;
  height: 44px;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
}
.homepage .navlist {
  margin: 0px;
  padding: 0px;
  height: inherit;
}
.homepage .navbar li {
  display: inline-block;
  font-family: "Sen", sans-serif;
  font-size: 24px;
  margin-right: 4px;
  margin-left: 0px;
  height: 42px;
}
.homepage .navbar li a {
  color: var(--main-navbar-color);
  position: relative;
  bottom: 10px;
  padding: 4px;
  border-radius: 2px;
  text-decoration: none;
  transition: 0.25s;
}
.homepage .navbar svg {
  position: relative;
  top: 12px;
  width: 24px;
  height: 24px;
  padding: 8px;
}
.homepage .navbar li a:hover {
  color: white;
  fill: white;
}
.homepage .navbar li#resume a:hover {
  background-color: #0f4d92;
}
.homepage .navbar li#email a:hover {
  background-color: #4285f4;
}
.homepage .navbar li#linkedin a:hover {
  background-color: #0077b5;
}
.homepage .navbar li#github a:hover {
  background-color: #0366d6;
}

.homepage .navbar span {
  font-size: 18px;
  padding-right: 4px;
}

.homepage .photo {
  display: block;
  background-color: var(--main-secondary-bg-color);
  padding: 24px 48px 24px 48px;
}
.homepage .photo img#intro-bg {
  padding: 0px;
  margin-bottom: -4px;
  width: 100%;
}
.homepage .photo img#intro-circle {
  margin-bottom: -4px;
  height: 300px;
  border-radius: 100%;
}
.homepage .photo span {
  display: block;
  margin-top: 0px;
}
.homepage .photo span > h1 {
  margin: 4px 0 4px 0;
}
.homepage .parallax {
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.homepage p#link a {
  color: var(--main-secondary-color);
  transition: 0.4s ease;
}
.homepage p#link a:hover {
  color: var(--main-color);
}
p#category {
  font-size: 20px;
  font-weight: bolder;
}

.homepage section {
  display: block;
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
}
.homepage .container {
  display: block;
  border-top: 1px solid var(--main-tertiary-color);
  padding-top: 24px;
}
.homepage .container h2 {
  margin-bottom: 12px;
}
.homepage .container p {
  margin-bottom: 4px;
  text-indent: -1em;
  margin-left: 1em;
}
.homepage .container p#title {
  text-decoration-color: gray;
}
.homepage .container p#description {
  text-indent: 0;
}
.homepage .container a {
  text-decoration: none;
  color: inherit;
}
.homepage .container a:hover {
  text-decoration: underline;
}
.homepage .container span {
  display: block;
  margin-top: 0px;
  margin-bottom: 24px;
}
.homepage .container .line {
  padding: 28px;
  padding-top: 0px;
}
.homepage .container .row {
  display: block;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.07);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 24px;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 16px;
  margin-bottom: 24px;
  transition: 0.4s;
}
.homepage .container .row:hover {
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.11);
}
.homepage .container .table {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
}
.homepage .container .table .table-header {
  display: block;
  min-width: 150px;
  padding-top: 10px;
  padding-right: 36px;
}
.homepage .container .table .resources {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.homepage .container .table .resource {
  display: block;
  color: inherit;
  cursor: pointer;
  margin: 4px;
  padding: 10px 10px 6px 10px;
  border-radius: 4px;
  text-align: inherit;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: 0.25s;
}
.homepage .container .table .resource:hover {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.11);
}
.homepage .container .table .resource a:hover {
  text-decoration: none;
}
.homepage .container .expand {
  display: block;
  color: inherit;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 24px;
  padding-bottom: 18px;
  margin-left: -12px;
  margin-top: 16px;
  margin-bottom: 16px;
  border: none;
  text-align: inherit;
  outline: none;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.07);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}
.homepage .expand:hover {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.11);
}
.homepage .expand p {
  margin-bottom: 4px;
  text-indent: -1em;
  margin-left: 1em;
}
.homepage .content {
  margin-top: 0px;
  margin-bottom: 12px;
  margin-right: 12px;
  margin-left: 12px;
  height: auto;
  overflow: scroll;
  transition: height 0.4s ease-in-out;
}

@media only screen and (max-width: 740px) {
  .homepage {
    text-align: center;
  }
  .homepage .photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .homepage .photo img#intro-circle {
    padding-bottom: 24px;
  }
  .homepage .navbar span {
    font-size: 0px;
    padding-right: 0px;
  }
  .homepage .navbar {
    padding-left: 0px;
    padding-right: 0px;
  }
  .homepage .navbar li {
    float: left;
    margin-right: 0px;
    width: 25%;
    text-align: center;
  }
  .homepage .container p {
    text-indent: 0;
    margin-left: 0;
  }
  .homepage .container .table .table-header {
    display: none;
  }
  .homepage .container .table .resource {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.11);
  }
  .homepage .container .table .resource a p > small {
    display: none;
  }
}